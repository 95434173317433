import * as React from "react";
import { Link } from "gatsby";

import { Box, Text, useColorMode } from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

// markup
const Why = () => {
  const { colorMode } = useColorMode();
  return (
    <Box
      //  bg="gray.50"
      pt="40px"
      maxW={{ base: "xl", md: "4xl" }}
      mx="auto"
      px={{ base: "6", md: "40px" }}
    >
      <Header />
      <Box mt="100px">
        <Link to="/">
          <Text
            mt="40px"
            mb="20px"
            size="xl"
            fontWeight="extrabold"
            maxW="2xl"
            lineHeight="1.2"
            letterSpacing="tight"
            textTransform="uppercase"
            color={colorMode === "light" ? "gray.500" : "whiteAlpha.900"}
            _hover={
              colorMode === "light"
                ? { color: "#333" }
                : { color: "whiteAlpha.700" }
            }
          >
            <ChevronLeftIcon />
            Retour
          </Text>
        </Link>
        <Text
          fontSize={{ base: "44px", md: "68px" }}
          fontWeight="extrabold"
          lineHeight="1.1"
          bgGradient="linear(to-l,  #199ECF , #006ECF,)"
          bgClip="text"
          // color="blue.500"
        >
          Pourquoi devenir une Product First Company ?
        </Text>

        <Text fontSize="xl" my="40px">
          En regardant Apple, Slack, Uber ou MailChimp, vous comprendrez la
          signification d'<b>une organisation axée sur le Produit.</b> Ce n'est
          pas juste le fait que ces entreprises soient des startups, ce n'est
          pas juste un slogan marketing ou un buzz word à la mode, mais{" "}
          <b>
            une culture qu'elles ont adoptée et qui place l'expérience
            utilisateur au-dessus de tout, comme lien entre la vision et
            l'exécution, entre la stratégie et l'action.
          </b>
        </Text>
        <Text
          p="20px"
          bg="#199ECF"
          my="40px"
          color="white"
          fontSize="xl"
          fontStyle="italic"
        >
          70% des entreprises échouent par manque d'alignement entre la
          stratégie et l'opérationnel.
        </Text>
        <Text fontSize="xl" my="4">
          Il existe des milliers d’ouvrages sur la manière de développer une
          stratégie, mais très peu sur la manière de l’exécuter concrètement.
          Or,{" "}
          <b>
            {" "}
            dans la majorité des échecs stratégiques, c’est la qualité
            d’exécution qui est en cause plutôt que la nature de la stratégie
            elle-même.{" "}
          </b>
          <br />
          <br />
          Élaborer une vision, partager la mission de l’organisation, faire en
          sorte que les collaborateurs connaissent ses objectifs stratégiques de
          développement et ses valeurs, tout cela est indispensable (et
          finalement assez rare). Mais{" "}
          <b>
            cela ne suffit malheureusement pas pour que tous les collaborateurs
            travaillent dans le même sens et contribuent à l’atteinte des
            objectifs.
          </b>
          <br /> <br />
          Chaque niveau dans l’organisation (le comité de direction ou exécutif,
          les départements, les business units, les services transversaux, les
          services support...) est un petit monde en soi, avec ses rapports
          humains plus ou moins bienveillants, ses inimitiés, ses rapports de
          pouvoir, son histoire, ses centres d’intérêt…{" "}
          <b>un lieu où peut se diluer l’intention stratégique. </b>
          <br />
          <br />
          Ce désalignement est aussi dû à une rupture de compréhension des
          enjeux de la part des entités Conseil, d'un bout à l'autre de la
          chaine de valeur, entre des cabinets qui prodiguent leurs
          recommandations théoriques sans n'avoir jamais été dans la pratique —
          donc en lien direct avec les clients — et des agences d'UI/UX qui
          doivent refaire site ou application{" "}
          <b>
            sans avoir la faculté de pouvoir requestionner la raison d'être de
            l'entreprise.
          </b>{" "}
          <br /> <br />
        </Text>
        <Text fontSize="xl" my="4">
          Or, pour que cet alignement fonctionne, il faut qu'à{" "}
          <b>
            chaque niveau de l’organisation les projets conduits, les actions
            entreprises, les roadmaps techniques et marketing soient alignés
            avec la stratégie.
          </b>{" "}
          <br /> <br />
        </Text>
        <Text
          //   p="20px"
          //   bg="#FF0080"
          //   my="40px"
          //   color="white"
          fontSize="xl"
          //   fontStyle="italic"
        >
          PARSK est né de cette volonté d’aider les entreprises à réaligner leur
          stratégie et leur exécution à travers une approche Product First.
        </Text>
      </Box>
      <Footer />
    </Box>
  );
};

export default Why;
